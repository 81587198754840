export default {
  // toBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = error => reject(error);
  //   });
  // },
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  },
  decodeImageMimeFromBase64(base64) {
    const header = base64.charAt(0);
    switch (header) {
      case '/':
        return 'image/jpeg';
      case 'R':
        return 'image/gif';
      case 'i':
        return 'image/png';
      default:
        return false;
    }
  },
  base64ImagetoDataUri(base64) {
    const mime = this.decodeImageMimeFromBase64(base64);
    return `data:${mime};base64,${base64}`;
  },
};
