<template>
  <el-row
    class="content-header"
    type="flex"
    align="center"
  >
    <h2 class="content-header__title">
      {{ title }}
    </h2>
  </el-row>
</template>

<script>
export default {
  name: 'ContentHeader',
  props: {
    title: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
.content-header {
  height: 50px;
  background-color: $--table-header-background-color;

  &__title {
    padding: 0 $--main-padding;
    align-self: center;
    color: $--color-primary;
    text-transform: uppercase;
  }
}
</style>
