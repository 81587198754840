<template>
  <el-row
    class="content-wrapper"
    type="flex"
    justify="center"
    align="middle"
  >
    <el-col
      :span="span"
      class="content-wrapper__container"
    >
      <ContentHeader :title="title" />
      <div
        :style="bodyStyle"
        class="content-wrapper__body"
      >
        <slot />
      </div>
    </el-col>
  </el-row>
</template>

<script>
import ContentHeader from '@/components/ContentHeader.vue';

export default {
  name: 'ContentWrapper',
  components: {
    ContentHeader,
  },
  props: {
    title: { type: String, default: '' },
    span: { type: Number, default: 18 },
    bodyStyle: { type: Object, default: null },
  },
};
</script>

<style lang="scss">
.content-wrapper {
  width: 100%;

  &__container {
    box-shadow: $--box-shadow-light;
    background-color: $--color-white;
    margin-top: 40px;
    border-radius: $--border-radius-base;

  }

  &__body {
    padding: 50px;
  }
}
</style>
