
<template>
  <!-- eslint-disable vue/no-v-html -->
  <ContentWrapper
    :body-style="{padding: '1rem'}"
    :title="$t('news.detail.title')"
    :span="20"
    class="news-detail-view"
  >
    <div class="article">
      <div class="article__aside">
        <el-image
          class="article__image"
          :src="decodedImage"
          fit="cover"
        >
          <div
            slot="error"
            class="image-slot"
          />
        </el-image>
      </div>
      <div class="article__content content ql-snow">
        <span class="content__date">
          {{ news.publishedAt | date('DD MMMM YYYY') }}
        </span>
        <div class="content__row">
          <h2 class="content__title">
            {{ news.title }}
          </h2>
          <div
            v-if="news.pdfFileIds && news.pdfFileIds.length"
            class="content__attachments-list"
          >
            <span class="content__attachments-label">
              {{ $t('news.detail.attachments') }}
            </span>
            <el-button
              v-for="(pdf, index) in news.pdfFileIds"
              :key="index"
              class="content__attachment"
              size="small"
              icon="fas fa-paperclip"
              circle
              @click="downloadPdf(pdf, index)"
            />
          </div>
        </div>
        <div
          v-if="news.text"
          class="content__text ql-editor"
          v-html="news.text"
        />
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import fileSaver from 'file-saver';
import fileService from '@/lib/file';
import { newsApi } from '@/api';
import ContentWrapper from '@/components/ContentWrapper.vue';

export default {
  name: 'NewsDetailUser',
  components: {
    ContentWrapper,
  },
  data() {
    return {
      news: {},
    };
  },
  computed: {
    decodedImage() {
      if (this.news.image && this.news.image !== '') {
        return fileService.base64ImagetoDataUri(this.news.image);
      }
      return '';
    },
  },
  async created() {
    const showResponse = await newsApi.show(this.$route.params.newsId);
    if (showResponse.data) {
      this.news = showResponse.data;
    }
  },
  methods: {
    async downloadPdf(fileId, index) {
      const response = await newsApi.downloadAttachment(this.$route.params.newsId, fileId);
      const url = URL.createObjectURL(response.data);
      fileSaver.saveAs(url, `${this.$t('global.attachment')} ${index + 1}.pdf`);
    },
  },
};
</script>

<style lang="scss">

.news-detail-view {

  $main-block: '.article';
  #{$main-block} {
    #{$main-block}__aside {
      width: 100%;
      position: relative;
      padding-top: calc(1 / 3 * 100%);
      #{$main-block}__image {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: $--collapse-header-background-color;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
      }
    }

    #{$main-block}__content {
      $block-1: '.content';

      &#{$block-1} {
        padding: 2rem 1rem;
        #{$block-1}__date {
          font-size: $--font-size-small;
        }
        #{$block-1}__row {
          display: flex;
          justify-content: space-between;
          margin-top: 0.25rem;

          #{$block-1}__title {
            font-weight: bold;
            font-size: 2rem;
          }
          #{$block-1}__attachments-list {
            display: flex;
            align-items: center;

            #{$block-1}__attachments-label {
              margin-right: 1rem;
            }
            #{$block-1}__attachment {
              color: $--color-white;
              border: 0;
              height: 30px;
              width: 30px;
              margin: 0 3px;
              background-color: $table-action-blue-light;
              &:hover {
                filter: brightness(0.9);
              }
              i {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
        #{$block-1}__text {
          padding-left: 0;
          padding-right: 5rem;
        }

      }

    }


  }

}


</style>
